import { EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Modal, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { PrinterForm } from 'components/molecules/PrinterForm';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { AuthorizedPrinters, EditLegalEntity } from 'models/LegalEntities';
import { FC, useState } from 'react';

type Props = {
  index: number;
};

export const EditPrinterButton: FC<Props> = ({ index }) => {
  const [open, setOpen] = useState(false);
  const { setFieldValue, values: headerVals } = useFormikContext<EditLegalEntity>();
  const [{ value }, , { setValue }] = useField<AuthorizedPrinters>(`printers[${index}]`);
  const formik = useFormik<AuthorizedPrinters>({
    enableReinitialize: true,
    initialValues: {
      dataAreaId: headerVals.dataAreaId,
      name: value.name ?? '',
      address: value.address ?? '',
      port: value.port ?? 9100
    },
    onSubmit: (values) => {
      console.log(values);
      const filteredVendors = headerVals.printers.filter((vendor) => vendor.name !== value.name);
      if (filteredVendors.some((vendor) => vendor.name === values.name)) {
        message.error('Printer already exists');
        return;
      }
      setValue(values);
      formik.resetForm();
      setOpen(false);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Tooltip title="Edit">
        <Button style={{ border: '1px solid orange' }} size="small" onClick={() => setOpen(true)} icon={<EditOutlined style={{ color: 'orange' }} />} />
      </Tooltip>
      <Modal
        closable={false}
        open={open}
        okText="Submit"
        onCancel={() => {
          setOpen(false);
          formik.resetForm();
        }}
        onOk={() => {
          formik.handleSubmit();
        }}>
        <Card headStyle={{ background: 'rgba(15, 77, 146, 0.3)' }} title={<Title level={4}>Edit Printer</Title>} style={{ marginBottom: 35 }}>
          <PrinterForm />
        </Card>
      </Modal>
    </FormikProvider>
  );
};
