import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';

export const PrinterForm = () => {
  return (
    <Form layout="vertical">
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <TextInput fieldName="name" label="Printer Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="address" label="Printer Address" />
        </Col>
        <Col span={24}>
          <TextInput type="number" fieldName="port" label="Network Port" />
        </Col>
      </Row>
    </Form>
  );
};
